<template>
  <div class="container">
    <button @click="testMessage">Test message</button>
    {{ transportStatus }}
    <div>
<!--      <video :srcObject.prop="localStream"-->
<!--             autoplay-->
<!--             playsinline-->
<!--             style="width: 200px; height: 200px;">-->
<!--      </video>-->
    </div>
  </div>
</template>

<script>

import io from 'socket.io-client'
import { Device } from 'mediasoup-client'

export default {
  name: "index",
  data() {
    return {
      socketServer: 'http://localhost:3005',
      roomId: 4,
      userId: 2,
      isHost: true,
      socket: null,
      device: null,
      sendTransport: null,
      localStream: null,
      producer: null,
      transportConnectCallback: null,
      transportProduceCallback: null,
      transportStatus: null
    }
  },
  mounted() {
    this.startSocket()
  },
  methods: {
    async startSocket() {
      this.socket = io(this.socketServer, {
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000
      })
      await this.joinRoom()
      // this.getRtpCapabilities()
    },
    async joinRoom() {

      this.socket.emit('join-room', this.roomId, this.userId, this.isHost)  // expect 'iJoinedRoom' response

      this.socket.on('sfu-message', async (data) => {
        console.log('sfu-message-data', data)
        switch(data.type) {
          case 'iJoinedRoom':
            this.sendSFUMessage({ type: 'getRtpCapabilities'}) // expect 'gotRtpCapabilities' response
            break;
          case 'gotRtpCapabilities':
            await this.loadDevice(data.data)
            this.sendSFUMessage({ type: 'createProducerTransport' }) // expect 'producerTransportCreated' response
            break;
          case 'producerTransportCreated':
            await this.getUserMedia()
            this.sendTransport = this.device.createSendTransport(data.data)
            this.sendTransport.on('connect', async({dtlsParameters}, callback) => {
              console.log('transport connect')
              this.sendSFUMessage({
                type: 'connectProducerTransport',
                transportId: this.sendTransport.id,
                dtlsParameters
              })
              this.transportConnectCallback = callback
            })
            this.sendTransport.on('produce', async({kind, rtpParameters}, callback) => {
              console.log('transport on produce')
              this.sendSFUMessage({
                type: 'produce',
                transportId: this.sendTransport.id,
                kind,
                rtpParameters
              })
              this.transportProduceCallback = callback
            })
            this.sendTransport.on('connectionstatechange', state => {
              console.log(`transport state ${state}`)
              this.transportStatus = state
            })
            this.producer = await this.sendTransport.produce({ track: this.localStream.getVideoTracks()[0] })
            break;
          case 'producerConnected':
            this.transportConnectCallback()
            break;
          case 'produced':
            this.transportProduceCallback(data.data.id)
            break;
        }
      })
    },
    async loadDevice(routerRtpCapabilities) {
      this.device = new Device()
      await this.device.load({routerRtpCapabilities})
    },
    sendSFUMessage(data) {
      this.socket.emit('sfu-message', this.roomId, this.userId, data)
    },
    async getUserMedia() {
      if(!this.device.canProduce('video')) return
      this.localStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
    },
    testMessage() {
      this.socket.emit('message', this.roomId, 'asdf')
    }
  }
}
</script>